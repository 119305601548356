import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueMoment from 'vue-moment'
import VueI18n from 'vue-i18n'
import ru from './translations/ru'
import uz from './translations/uz'
import en from './translations/en'
import fi from './translations/fi'

Vue.config.productionTip = false

/* eslint @typescript-eslint/no-var-requires: "off" */
const moment = require('moment');
moment.locale(navigator.language);

Vue.use(VueI18n);

const messages = {
  en,
  ru,
  uz,
  fi
};

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages
});

Vue.use(VueMoment, {
  moment
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
