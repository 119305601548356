<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import Vue from 'vue';
import '@/assets/tailwind.css';

export default Vue.extend({
    name: 'Home',
    components: {},
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    created () {
        console.log(process.env.VUE_APP_API_ENDPOINT);
        document.addEventListener(
            'swUpdated', this.showRefreshUI, { once: true }
        )
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener (
                'controllerchange', () => {
                    if (this.refreshing) return
                    this.refreshing = true
                    window.location.reload ()
                }
            )
        }
    },
    methods: {
        showRefreshUI (e) {
            this.registration = e.detail
            this.updateExists = true
            setTimeout( () => {
                this.update()
            }, 1000)
        },

        update () {
            this.updateExists = false
            if (!this.registration || !this.registration.waiting) { return; }
            this.registration.waiting.postMessage('skipWaiting');
        }
    }
});

</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;600;700&display=swap');
    body {
        font-family: 'Rubik', sans-serif;
    }
</style>
