import { BaseModel } from "@/feathers-client";
import Customer from "./customer";
import { FeedbackQuestion } from "./feedback-question";

export class FeedbackAnswer extends BaseModel {

    constructor(data?: any, options?: any) {
        super(data, options);
        this._id = data?._id;
        this.companyId = data?.companyId;
        this.questionId = data?.questionId;
        this.customerId = data?.customerId;
        this.answer = data?.answer;
    }

    static modelName = "FeedbackAnswer";

    public _id: string;
    public companyId: string;
    public questionId: FeedbackQuestion['_id'];
    public customerId: Customer['_id'];
    public answer: string;
}
