import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
//import Login from '../Pages/Account/Login.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: () => import('../Layouts/Default.vue'),
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('../Pages/Home/Index.vue')
            },
            {
                path: '/appointments',
                name: 'Appointments',
                component: () => import('../Pages/Appointments.vue')
            },
            {
                path: '/contact',
                name: 'Contact',
                component: () => import('../Pages/Contact.vue')
            },
        ]
    },
    {
        path: '/',
        component: () => import('../Layouts/Blank.vue'),
        children: [
            {
                path: '/list-company',
                name: 'ListCompany',
                component: () => import('../Pages/ListCompany.vue')
            },
            {
                path: '/c/:companyAlias',
                name: 'BookAppointment',
                component: () => import('../Pages/Calendar/Index.vue'),
                meta: { title: 'Book Appointment' },
                props: true,
            },
        ]
    },
    {
        path: '/t/:token',
        component: () => import('@/Pages/TokenHandler/TokenHandler.vue'),
        props: true
    },
    {
        path: '/feedback/:questionnaireId',
        name: 'Feedback',
        component: () => import('../Pages/Feedback/Index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../Pages/Account/LoginPhone.vue')
    }

]

const router = new VueRouter({
    routes,
    mode: 'history',
})

export default router
