import api from '@/plugins/api'
import Vue from 'vue'
import Vuex from 'vuex'
import feathersClient, {FeathersVuex, makeServicePlugin} from "@/feathers-client";
import {FeedbackQuestionnaire} from "@/store/models/feedback-questionnaire";
import Appointment from './models/appointment'
import Customer from './models/customer'
import CustomerCompany from './models/customer-company'
import { FeedbackAnswer } from './models/feedback-answer';

Vue.use(Vuex)
Vue.use(FeathersVuex)

export default new Vuex.Store({
  plugins: [
      makeServicePlugin({
          Model: FeedbackQuestionnaire,
          service: feathersClient.service("feedback-questionnaire"),
          servicePath: 'feedback-questionnaire'
      }),
      makeServicePlugin({
          Model: FeedbackAnswer,
          service: feathersClient.service("feedback-answers"),
          servicePath: 'feedback-answers'
      })
  ],
  state() {
    return {
      currentCustomer: {},
      allProfiles: [],
      appointments: [],
    }
  },
  mutations: {
    setCurrentCustomer(state: any, customer: Customer){
      state.currentCustomer = customer;
    },
    setAllProfiles(state: any, profiles: Customer[]){
      state.allProfiles = profiles;
    },
    setAppointments(state: any, appointments: Appointment[]){
      state.appointments = appointments.sort((a: Appointment, b: Appointment) => a.startAt > b.startAt ? 1 : -1);
    },
    updateJWTToken(state: any, value: any){
      window.localStorage.setItem('feathers-jwt', value);
    },
    updateCustomerId(state, id: string){
      window.localStorage.setItem('customerId', id);
    },
  },
  actions: {
    fetchAllProfiles({commit}){
      if (!window.localStorage.getItem('feathers-jwt')) return;
      return api.get('/cabinet/get-profiles').then(response => {
        commit('setAllProfiles', response.data);
      });
    },
    
    selectProfile({commit, state, dispatch}, id: string){
      const selectedProfile = state.allProfiles.find((profile: Customer) => profile.companyId === id);
      
      commit('setCurrentCustomer', selectedProfile);
      dispatch('setCustomerId', selectedProfile._id);
    },

    fetchCurrentCustomer({commit, getters, dispatch}){
      if (!getters.customerId()) return;
      return api.get('/cabinet/get-profiles/' + getters.customerId()).then(response => {
        commit('setCurrentCustomer', response.data);
        dispatch('fetchAppointments');
        dispatch('fetchAllProfiles');
      });
    },

    fetchAppointments({commit, getters}){
      if (!getters.customerId()) return;
      return api.get('/cabinet/appointments/' + getters.customerId())
          .then((response) => {
              commit('setAppointments', response.data);
          });
    },
    
    logout({commit}){
      commit('setAppointments', []);
      commit('setAllProfiles', []);
      commit('setCurrentCustomer', {});
      window.localStorage.removeItem('feathers-jwt');
    },

    setCustomerId({commit, dispatch}, id){
      commit('updateCustomerId', id);

      dispatch('fetchCurrentCustomer');
    },
  },
  getters: {
    myCompanies(state: any): CustomerCompany[]{
      return state.allProfiles.map((el: Customer) => el.company);
    },
    customerId: () => (): string | null => {
      return window.localStorage.getItem('customerId')
    }
  },
  modules: {}
})
