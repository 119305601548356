import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  if (!config.headers) config.headers = {};
  config.headers["Authorization"] = "Bearer " + window.localStorage.getItem("feathers-jwt");
  return config;
})

export default api;
