import {BaseModel} from "@/feathers-client";
import {FeedbackQuestion} from "@/store/models/feedback-question";

export enum FeedbackQuestionType {
    singleChoice = "SINGLE_CHOICE",
    multipleChoice = "MULTIPLE_CHOICE",
    inputText = "INPUT_TEXT",
}

export class FeedbackQuestionnaire extends BaseModel {
    constructor(data?: any, options?: any) {
        super(data, options);
        this._id = data?._id;
        this.name = data?.name;
        this.questions = data?.questions;
    }

    static modelName = "FeedbackQuestionnaire";

    public _id: string;
    public name: string;
    public questions: FeedbackQuestion[]
}
